export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Projects',
    route: 'project-list',
    icon: 'ListIcon',
    Permission: '',
  },

  {
    title: 'Invoices',
    icon: 'FileTextIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'invoice-add',
        icon: 'UserPlusIcon',
        Permission: 'invoice_add',
      },
      {
        title: 'List',
        route: 'invoice-list',
        icon: 'ListIcon',
        Permission: '',
      },
      {
        title: 'Edit',
        route: 'invoice-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Billing',
    icon: 'DollarSignIcon',
    Permission: '',
    children: [
      {
        title: 'Create',
        route: 'billing-create',
        icon: 'UserPlusIcon',
        Permission: '',
      },
      {
        title: 'Approval',
        route: 'billing-approval',
        icon: 'BellIcon',
        Permission: '',
      },
      {
        title: 'Finalize',
        route: 'billing-confirmation',
        icon: 'CheckIcon',
        Permission: '',
      },
      {
        title: 'History',
        route: 'billing-history',
        icon: 'ArchiveIcon',
        Permission: '',
      },
    ],
  },

  {
    title: 'Reports',
    icon: 'PieChartIcon',
    Permission: '',
    children: [
      {
        title: 'Overview',
        route: 'report',
        icon: 'BarChartIcon',
        Permission: '',
      },
      {
        title: 'Employee Hours',
        route: 'employee-hours',
        icon: 'ClockIcon',
        Permission: '',
      },
      {
        title: 'Materials Used',
        route: 'materials-used',
        icon: 'SettingsIcon',
        Permission: '',
      },
    ],
  },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: '',
    children: [
      {
        title: 'Add',
        route: 'user-add',
        icon: 'UserPlusIcon',
        Permission: '',
      },
      {
        title: 'List',
        route: 'user-list',
        icon: 'ListIcon',
        Permission: '',
      },
      {
        title: 'Edit',
        route: 'user-edit',
        icon: 'EditIcon',
        disabled: true,
        Permission: '',
      },
    ],
  },

  {
    title: 'Customers',
    route: 'customer-list',
    icon: 'UsersIcon',
    Permission: '',
  },

  {
    title: 'Timesheet',
    route: 'weekly-timesheet',
    icon: 'CalendarIcon',
    Permission: '',
  },


  {
    title: 'Settings',
    route: 'settings-dashboard',
    icon: 'SettingsIcon',
    Permission: '',
  },

  {
    title: 'Sign Out',
    route: 'signout',
    icon: 'LogOutIcon',
    Permission: '',
  },

  {
    title: 'Our Mantra',
    route: 'our-mantra',
    icon: 'HarvardIcon',
    Permission: '',
  },
];
