<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <feather-icon icon="ArrowLeftIcon" size="28" @click="backRouter()" class="mr-2 text-white cursor-pointer" />
      <feather-icon icon="ArrowRightIcon" size="28" @click="nextRouter()" class="text-white cursor-pointer" />
    </div>

    <div class="align-items-center flex-grow-1 d-none text-center justify-content-center d-lg-flex" v-if="userData.company != undefined && userData.company != null">
      <!-- <img :src="baseURL + userData.company.logoLink" alt="" height="70px" srcset="" /> -->

      <b-img-lazy v-bind="mainProps" class="p-50" :src="baseURL + userData.company.logoLink" v-if="userData.company.logoLink != null" />
      <b-img-lazy v-bind="mainProps" class="p-50" :src="baseURL + 'company.png'" v-else />
      <h4 class="ml-2 text-white mt-1 p-1">{{ userData.company.name }}</h4>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ userData.name }} {{ userData.surname }}</p>
            <span class="user-status" v-if="userData.role == 'manager'">Manager</span>
            <span class="user-status" v-else>User</span>
          </div>

          <b-avatar size="40" variant="light-info" badge :src="profileURL" class="badge-minimal" badge-variant="success" />
          <!-- <b-avatar size="40" variant="light-primary" badge src="" class="badge-minimal" badge-variant="success" />  -->
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="navigateProfile">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>
            <b-link class="alert-link mt-5 mb-5">
              Logout
            </b-link></span
          >
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { initialAbility } from '@/libs/acl/config';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
import store from '@/store';
import axiosIns from '@/libs/axios';

export default {
  data() {
    return {
      baseURL: store.state.app.baseURL,
      email: localStorage.getItem('email'),
      userData: JSON.parse(localStorage.getItem('userData')),
      profileURL: store.state.app.profileURL + JSON.parse(localStorage.getItem('userData')).profile,

      mainProps: {
        blank: true,
        blankColor: '#FFF',
        height: 70,

        class: 'm1',
        block: true,
      },
    };
  },

  created() {
    this.userDetails();
  },

  methods: {
    backRouter() {
      router.go(-1);
    },

    nextRouter() {
      router.go(1);
    },

    navigateProfile() {
      if (router.currentRoute.name != 'user-profile') {
        this.$router.push({ name: 'user-profile' });
      }
    },

    userDetails() {
      axiosIns
        .get(`getUserById/${this.userData.id}`)
        .then((res) => {
          this.userData = res.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem('userData');

      // Reset ability
      /*
      this.$ability.update(initialAbility);
      */

      // Redirect to login page
      this.$router.push({ name: 'auth-login' });
    },
  },

  computed: {},

  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
